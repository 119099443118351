import { useState, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'

// Components
import ButtonComponent from '@/components/ui/button'
import AirportSearchMobile from '@/components/search/AirportSearchMobile'
import DatesMobile from '@/components/search/DatesMobile'
import PassengersMobile from '@/components/search/PassengersMobile'
import MultiMobile from '@/components/search/MultiMobile'
import Modal from '@/components/ui/modal'

import useTranslation from '@/hooks/translation'
import { getCookie } from '@/utils/cookies'
import { nextMonth, getDays } from '@/logic/ContextualCalendarCache'
import { nextVersion, useMonotonicState } from '@/v2/common/monotonic-state'

// SVG
import Arrow from '@/assets/svg/arrow-home.svg'
import useAnalytics from '@/hooks/analytics'
import { useCallback } from 'react'

const steps = {
  form: 0,
  multi: 1,
  origin: 2,
  destination: 3,
  dates: 4,
  passengers: 5,
}

function trackStepChange(track, step) {
  // step to text
  const text = Object.entries(steps)
    // filter by value
    .filter(([, value]) => value === step)
    // extract the first key that matches
    .map(([key]) => key)?.[0]

  if (text) {
    track('prebooking_mobile_change_step', {
      step: text,
    })
  }
}

function useStep(initial) {
  const { track } = useAnalytics()
  const [step, setStep] = useState(initial)
  const update = useCallback((newStep) => {
    setStep(newStep)
    trackStepChange(track, newStep)
  })

  return [step, update]
}

export default function SearchComponent({ search, onCreate }) {
  const router = useRouter()
  const { t } = useTranslation()

  const [step, setStep] = useStep(steps.form)
  const [sliceId, setSliceId] = useState(search.slices[0].id)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [contextualDays, setContextualDays] = useMonotonicState({})
  const slice = useMemo(
    () => search.slices.find((S) => S.id === sliceId),
    [search.slices, sliceId]
  )
  const origin = slice?.origin
  const destination = slice?.destination
  const [departureDate, setDepartureDate] = useState(slice?.date)

  const selectAirports = (val) => {
    if (step === steps.origin) {
      search.setOrigin(val, sliceId)
      setStep(steps.destination)
    } else if (step === steps.destination) {
      search.setDestination(val, sliceId)
      setStep(steps.dates)
    }
  }

  const selectDates = () => {
    if (search.tripType === 'multi') {
      setStep(steps.multi)
    } else {
      setStep(steps.passengers)
    }
  }

  const onPassengerAndClassConfirm = () => {
    if (search.isValid) {
      setIsSubmitting(true)
      search
        .createSearch({
          ignoreSpecialOffers: getCookie('ulysse:partnerships') ? false : true,
        })
        .then(({ data }) => {
          if (data?.id) {
            onCreate?.(data)

            const query = { id: data.id }
            router.push({
              pathname: '/search/[id]',
              query,
            })
          } else {
            setIsSubmitting(false)
          }
        })
        .catch((err) => {
          console.error(err)
          setIsSubmitting(false)
        })
    } else {
      setStep(steps.form)
    }
  }

  useEffect(() => {
    if (search.tripType !== 'multi' && sliceId !== search.slices[0].id) {
      setSliceId(search.slices[0].id)
    }
  })

  const dates = search.getDates(sliceId)
  const showPrefill = useMemo(
    () =>
      !!(
        search.slices[0]?.origin?.value && search.slices[0]?.destination?.value
      ),
    [search]
  )

  // the contextual days
  const updateEffect = async () => {
    // we can't search if no destination and no origin
    if (!origin || !destination) {
      return
    }

    const start = new Date()
    const end = nextMonth(start, 6)

    const v = nextVersion()
    const r = await getDays(
      origin.value,
      destination.value,
      search.tripType,
      search.partnership,
      departureDate,
      start,
      end
    )
    setContextualDays(r || {}, v)
  }

  useEffect(() => {
    updateEffect()
  }, [search.tripType, search.partnership, departureDate, origin, destination])

  return (
    <div className="bg-white rounded shadow-double flex items-center justify-between">
      <Modal
        isVisible={step !== steps.form}
        isClosable={false}
        onClose={() => setStep(steps.form)}
        trigger={
          <ButtonComponent
            className="flex items-center p-30 text-left flex-1"
            onClick={() =>
              setStep(
                search.tripType === 'multi'
                  ? steps.multi
                  : showPrefill
                  ? steps.destination
                  : steps.origin
              )
            }
            type="button"
            unstyled
          >
            <p className="font-bold text-primary">
              {showPrefill
                ? `${search.slices[0]?.origin?.selected} - ${search.slices[0]?.destination?.selected}`
                : search.tripType === 'multi'
                ? t('components.formMobile.createTrip')
                : t('components.formMobile.origin')}
            </p>
          </ButtonComponent>
        }
      >
        {({ onClose }) =>
          step === steps.multi ? (
            <MultiMobile
              search={search}
              previous={onClose}
              onOriginClick={(id) => {
                setStep(steps.origin)
                setSliceId(id)
              }}
              onDestinationClick={(id) => {
                setStep(steps.destination)
                setSliceId(id)
              }}
              onDateClick={(id) => {
                setStep(steps.dates)
                setSliceId(id)
              }}
              onConfirm={() => {
                setStep(steps.passengers)
              }}
            />
          ) : step === steps.origin || step === steps.destination ? (
            <AirportSearchMobile
              isOrigin={step === steps.origin}
              origin={slice.origin}
              destination={slice.destination}
              onOriginClicked={() => setStep(steps.origin)}
              previous={() => {
                if (step === steps.destination) {
                  setStep(steps.origin)
                } else if (search.tripType === 'multi') {
                  setStep(steps.multi)
                } else {
                  onClose()
                }
              }}
              onSelect={selectAirports}
            />
          ) : step === steps.dates ? (
            <DatesMobile
              previous={() => {
                if (search.tripType === 'multi') {
                  setStep(steps.multi)
                } else {
                  setStep(steps.destination)
                }
              }}
              contextualData={contextualDays}
              isRange={true}
              selectedDates={dates}
              onSelect={(dates) => {
                search.setDatesAndTripType(dates, sliceId)
                selectDates()
              }}
              minDate={search.getMinDate(sliceId)}
              onDepartureDateChange={setDepartureDate}
            />
          ) : step === steps.passengers ? (
            <PassengersMobile
              previous={() => {
                setStep(search.tripType === 'multi' ? steps.multi : steps.dates)
              }}
              numAdults={search.numAdults}
              numChildren={search.numChildren}
              numBabies={search.numBabies}
              onNumAdultsChange={search.setNumAdults}
              onNumChildrenChange={search.setNumChildren}
              onNumBabiesChange={search.setNumBabies}
              cabinClass={search.cabinClass}
              onCabinClassChange={search.setCabinClass}
              onConfirm={onPassengerAndClassConfirm}
              isSubmitting={isSubmitting}
            />
          ) : null
        }
      </Modal>
      <Arrow className="mr-[32px] w-[13px] h-auto" />
      {/* <ButtonComponent
        color="light-primary"
        size="sm"
        className="m-10"
        onClick={() =>
          search.setTripType((tripType) =>
            tripType == 'roundtrip' ? 'oneway' : 'roundtrip'
          )
        }
      >
        {search.tripType === 'roundtrip'
          ? t('components.formMobile.roundtrip')
          : search.tripType === 'oneway'
          ? t('components.formMobile.oneway')
          : t('components.formMobile.multi')}
      </ButtonComponent> */}
    </div>
  )
}
