// Components
import Button from '@/components/ui/button'

// Icons
import BackArrow from '@/assets/svg/back-arrow.svg'
import Arrow from '@/assets/svg/arrow.svg'

// Hooks
import useDates from '@/hooks/dates'
import useTranslation from '@/hooks/translation'

function RowButton({ label, value, labelId, className, onClick }) {
  return (
    <Button
      className={`h-60 px-20 text-left ${className}`}
      aria-labelledby={labelId}
      type="button"
      onClick={onClick}
      unstyled
    >
      <span id={labelId} className="block text-12 text-dark-60 leading-none">
        {label}
      </span>
      <span className={`block font-bold ${value ? 'text-primary' : ''}`}>
        {value || '-'}
      </span>
    </Button>
  )
}

export default function MultiMobile({
  previous,
  search,
  onOriginClick,
  onDestinationClick,
  onDateClick,
  onConfirm,
}) {
  const { formatDate } = useDates()

  const { t } = useTranslation()

  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 bg-white flex flex-col z-20">
      <header className="border-b border-dark border-opacity-10">
        <Button onClick={previous} className="p-20" unstyled>
          <BackArrow className="opacity-60" />
        </Button>
        <span className="text-17 font-bold py-20 pr-20 ">
          {t('components.multiMobile.back')}
        </span>
      </header>
      <div className="relative flex-1 p-20">
        {search.slices.map((slice) => (
          <div key={slice.id} className="pb-18">
            <div className="rounded-5 border border-dark-40 flex items-center">
              <RowButton
                label={t('components.multiMobile.departure')}
                value={slice.origin?.value}
                labelId={`origin-label-${slice.id}`}
                className="flex-1"
                onClick={() => {
                  onOriginClick(slice.id)
                }}
              />
              <Arrow />
              <RowButton
                label={t('components.multiMobile.arrival')}
                value={slice.destination?.value}
                labelId={`destination-label-${slice.id}`}
                className="flex-1"
                onClick={() => {
                  onDestinationClick(slice.id)
                }}
              />
              <RowButton
                label={t('components.multiMobile.date')}
                value={slice.date ? formatDate(slice.date, 'd MMM') : undefined}
                labelId={`dates-label-${slice.id}`}
                className="w-2/5 border-l border-dark-40"
                onClick={() => {
                  onDateClick(slice.id)
                }}
              />
            </div>
            {search.slices.length > 2 ? (
              <Button
                className="block ml-auto text-14 text-primary py-5"
                type="button"
                onClick={() => {
                  search.removeSlice(slice.id)
                }}
                unstyled
              >
                <span className="text-18">x</span>{' '}
                <span className="underline">
                  {t('components.multiMobile.remove')}
                </span>
              </Button>
            ) : null}
          </div>
        ))}
        <Button
          outline
          onClick={() => {
            search.addSlice({})
          }}
          className="w-full"
        >
          {t('components.multiMobile.add')}
        </Button>
      </div>
      <div className="border-t border-dark border-opacity-20 p-20">
        <Button
          className="w-full"
          size="xl"
          disabled={!search.isValid}
          onClick={onConfirm}
        >
          {t('components.multiMobile.confirm')}
        </Button>
      </div>
    </div>
  )
}
