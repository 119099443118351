import { useState, useCallback } from 'react'

let VERSION = 0

export function nextVersion() {
  return ++VERSION
}

type VersionnedState<S> = {
  version: number
  value: S
}

type SetValueFunction<S> = (prevState: S, version: number) => S
type SetValue<S> = S | SetValueFunction<S>

export type DispatchStateForVersion<S> = (
  value: SetValue<S>,
  version?: number
) => void

export function useMonotonicState<S>(
  initial: S
): [S, DispatchStateForVersion<S>, number] {
  const [state, setState] = useState<VersionnedState<S>>({
    version: 0,
    value: initial,
  })

  const update = useCallback(
    (value: SetValue<S>, version?: number) => {
      const v = version || nextVersion()
      setState((current) => {
        if (v > current.version) {
          if (typeof value === 'function') {
            return {
              version: v,
              value: (value as SetValueFunction<S>)(
                current.value,
                current.version
              ),
            }
          }
          return { version: v, value }
        }
        return current
      })
    },
    [setState]
  )

  return [state.value, update, state.version]
}
